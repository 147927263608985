import momemt from 'moment';

export default function Events() {
	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/Tears For Fears/events?app_id=45PRESS_tff',
		method: 'GET',
		dataType: 'json',
		error: () => {
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			console.log( data );
			if ( data.length ) {
				for ( let event of data ) {
					var eventlocation ='';
					if (event.venue.city == "Kirkham"){
						eventlocation = "Lytham Festival";
					} else {
						eventlocation = event.venue.city + ' ' + event.venue.region;
					}
					html += '<div class="event">';
					html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM DD' ) + '</div>';
					html += '<div class="event-location">' + eventlocation + '</div><div class="event-location">' + event.venue.name + '</div>';
					html += '<div class="event-links">';
					for ( let offer of event.offers ) {
						html += '<a href="' + offer.url + '" target="_blank" class="btn">Tickets</a>';
					}
					html += '</div>';
					html += '</div>';
				}
				html+='<div class="more"><a href="#" class="btn">view more dates</a></div>';
				events.html( html );

				$( '#events .more' ).hide();
				if ( $( '#events>div' ).length > 6 ) {
					$( '#events .more' ).show();
				}
			} else {
				events.html( '<p>No upcoming events.</p>' );
			}
			$( '#events .more a' ).on( 'click', function ( e ) {
				e.preventDefault();
				$( '#events' ).addClass('seeall');
				$( this ).hide();
			} );
		},
	} );

	

}