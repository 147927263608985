import 'owl.carousel';
import 'magnific-popup';
import Events from './events';

jQuery( document ).ready(function($) {
  Events();
  $('.owl-carousel').owlCarousel({
      loop:true,
      items: 1,
      dots: false,
      nav: true,
      margin: 25,
      navText: [
        "<i class='fas fa-caret-left'></i><span class='sr-only'>Prev</span>",
        "<i class='fas fa-caret-right'></i><span class='sr-only'>Next</span>"
      ]
  });

  // $.magnificPopup.open({
  //   items: { 
  //     src: '#popup',
  //     type: 'inline'
  //   }
  // });

  $('.scroll').on('click', function(e) {
    const TARGET = $(this).attr('href');
  
    e.preventDefault();
  
    $('body, html').animate({
      scrollTop: $(TARGET).offset().top
    });
  }); 
});